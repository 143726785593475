const prefix = 'jak_'
const keys = [
  prefix + 'user'
]

export function setItem (key, data) {
  try {
    localStorage.setItem(prefix + key, JSON.stringify(data))
  } catch (error) {
    return error
  }
}

export function getItem (key, data) {
  try {
    return JSON.parse(localStorage.getItem(prefix + key))
  } catch (error) {
    return error
  }
}

export function removeItem (key) {
  localStorage.removeItem(prefix + key)
}

export function removeAll () {
  keys.map(item => {
    localStorage.removeItem(item)
  })
}
