import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    component: () => import('@/views/login/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Index.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/user/ForgotPassword.vue')
  },
  {
    path: '/reset-sent',
    name: 'Reset Sent',
    component: () => import('@/views/user/ResetSent.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/views/user/ResetPassword.vue')
  },
  {
    path: '/success-password',
    name: 'Success New Password',
    component: () => import('@/views/user/SuccessPassword.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/layout/Layout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'beranda',
        name: 'Beranda',
        component: () => import('@/views/dashboard/beranda/Index.vue')
      },
      {
        path: 'merchant',
        name: 'Merchant',
        component: () => import('@/views/dashboard/merchant/Index.vue')
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
