<template>
  <v-app>
    <router-view />
    <notify />
  </v-app>
</template>

<script>
import Notify from '@/components/Notify'
export default {
  name: 'App',
  components: {
    Notify
  },

  data: () => ({
    //
  }),

  created() {
    this.$store.dispatch('setHostName')
  }
};
</script>
