import router from './router'
import store from './store'
// import { getToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.token) {
    next({ name: 'Login' })
  } else {
    next()
  }
})
